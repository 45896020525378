<template>
    <b-card no-body>
        <b-card-body class="text-center">
            <loading v-if="loading"/>
            <common-table-custom
                show-details
                :get-details-data=true
                @detailsCallback="getDetails"
                title="Disparos recentes"
                v-bind="configTableShot"
            >
                <template v-slot:filters>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink">
                            <select v-model="configTableShot.filters.order_column"
                                    class="form-control like-dropdown my-1">
                                <option :value="null" hidden selected>Filtrar por</option>
                                <option value="created_at">Data de Criação</option>
                            </select>
                        </div>
                        <div class="flex-shrink">
                            <select v-model="configTableShot.filters.order"
                                    class="form-control like-dropdown mx-md-2 my-1">
                                <option :value="null" hidden selected>Classificar por</option>
                                <option value="asc">Ascendente</option>
                                <option value="desc">Descendente</option>
                            </select>
                        </div>
                        <div class="input-group input-group-merge search my-1 w-100 flex-grow-1" role="group"
                             style="width: 300px !important;">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <feather-icon icon="SearchIcon" size="25"/>
                                </div>
                            </div>
                            <input
                                v-model="configTableShot.filters.search"
                                class="form-control"
                                placeholder="Buscar disparo"
                                type="text"
                            >
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <td class="text-left">Total</td>
                    <td>{{ configTableShot.misc ? configTableShot.misc.total : 0 }}</td>
                    <td
                        :colspan="5"
                        class="text-right"
                    >
                        <b-link
                            v-if="configTableShot.misc && configTableShot.misc.current_page != configTableShot.misc.last_page"
                            @click="getShots(configTableShot.misc.current_page + 1)"
                        >
                            + Mostrar mais
                        </b-link>
                    </td>
                </template>

                <template v-slot:details="item">
                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th class="text-left">Número</th>
                                <th>Taxa de disparo</th>
                                <th>Avaliação</th>
                            </tr>
                        </thead>
                        <tr v-for="(phone, i) in item.data.phones" :key="`phone-${i}`">
                            <td class="text-left">
                                {{ formatPhone(phone.phone) }}
                            </td>
                            <td>
                                {{ phone.per_hour }} / h
                            </td>
                            <td>
                                {{ phone.rating }}
                            </td>
                        </tr>
                    </table>
                </template>

                <template v-slot:custom="{ data }" v-if="$role(['campaigns.update'])">
                    <div class="d-flex align-items-center justify-content-center">
                        <span
                            role="button"
                            @click="pauseShot(data._id)"
                        >
                            <span class="ml-2">
                                {{ data.paused ? 'Ativar' : 'Pausar' }}
                            </span>
                        </span>
                    </div>
                </template>
            </common-table-custom>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardBody,
    BLink,
} from "bootstrap-vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import api from '@/api';
import { formatPhone } from "@/api/functions";
import { configTableShot } from '@/views/pages/dashboard/campaigns/constants';
import Loading from "@/views/components/Loading.vue";

export default {
    components: {
    BCard,
    BCardBody,
    CommonTableCustom,
    BLink,
    Loading
},
    data() {
        return {
            configTableShot: configTableShot,
            loading: false
        };
    },
    created() {
        this.getShots();
    },
    watch: {
        'configTableShot.filters': {
            handler() {
                clearTimeout(this.configTableShot.timeout);
                this.configTableShot.timeout = setTimeout(this.getShots, 500)
            },
            deep: true
        }
    },
    methods: {
        formatPhone,
        getShots(page = 1) {
            this.loading = true;
            const params = {page, ...this.configTableShot.filters};

            api
                .get('/shots', {params})
                .then(response => {
                    const {type, body, misc} = response.data;

                    if (type != 'success') return;
                    if (page > 1) this.configTableShot.items.push(...body);
                    else this.configTableShot.items = body;

                    this.configTableShot.misc = misc;
                })
                .finally(() => this.loading = false);
        },
        getDetails(data) {
            this.loading = true
            api
                .get(`/shots/${data.item._id}/details`)
                .then(response => {
                    const {body} = response.data
                    this.configTableShot.items[data.index].phones = body.phones
                })
                .finally(() => this.loading = false)
        },
        pauseShot(id) {
            const index = this.configTableShot.items.findIndex(e => e._id == id);
            if(index < 0) return;

            const shot = this.configTableShot.items[index];

            this.loading = true;
            api
                .put(`/shots/${id}`, {paused: !shot.paused})
                .then(response => {
                    const {type, body, message} = response.data;

                    if (type == 'success') {
                        return Object.assign(this.configTableShot.items[index], body);
                    }

                    this.$swal('Erro', message, 'error');
                })
                .catch(err => {
                    let message = 'Não foi possível pausar campanha';

                    if(err.response) message = err.response.data.message;
                    this.$swal('Erro', message, 'error');
                })
                .finally(() => this.loading = false);
        },
    }
};
</script>

<style>
</style>
